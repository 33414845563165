import { useEffect, useState } from 'react';

/**
 * Permet de récupérer la visibilité d'un élément dans le viewport
 * ```tsx
 *  const [ref, visible] = useElementVisibility();
 *  return <div ref={ref}>Je suis {visible ? 'visible' : 'invisible'}</div>;
 * ```
 */
export const useElementVisibility = () => {
  const [visible, setVisible] = useState(false);
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      setVisible(entries.some(entry => entry.isIntersecting));
    });
    if (element) observer.observe(element);
    return () => observer.disconnect();
  }, [element]);

  return [setElement, visible] as const;
};
