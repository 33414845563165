import { getMediaQuery } from '~/src/common/utils/style';

// Espace entre les cards
export const MOBILE_ITEM_GAP = 8;
export const DESKTOP_ITEM_GAP = 16;

// Item à droite de la liste permettant d'indiquer du contenu disponible à scroller
export const MOBILE_ITEM_PREVIEW_WIDTH = 48;

// La largeur des cartes a été définie via des media queries afin de pouvoir respecter les contraintes suivantes :
// - La position de la carte la plus à droite sur desktop est calée sur les marges de la page
// - Pour respecter la contrainte précédente, la largeur des cartes n'est pas fixe et est calculée par le navigateur
// - Un 1er rendu réalisé purement en CSS afin de ne pas avoir de CLS (Cumulative Layout Shift)
export const CONFIG_SWIPER = [
  { mq: getMediaQuery(0, 635), count: 2 },
  { mq: getMediaQuery(635, 934), count: 3 },
  { mq: getMediaQuery(934, 1140), count: 4 },
  { mq: getMediaQuery(1140, 1430), count: 5 },
  { mq: getMediaQuery(1430, 1640), count: 6 },
  { mq: getMediaQuery(1640, 1850), count: 7 },
  { mq: getMediaQuery(1850, 2060), count: 8 },
  { mq: getMediaQuery(2060, null), count: 9 },
];

export const CONFIG_SWIPER_DEFAULT = { count: 6 };
