import { useEffect, useState } from 'react';

const useScrollPosition = (scrollContainer: HTMLElement | null) => {
  const [scrollPosition, setScrollPosition] = useState<'start' | 'end' | 'middle'>('start');

  useEffect(() => {
    if (!scrollContainer) return;

    const getScrollPosition = () => {
      // Largeur du contenu scrollable - largeur de la fenêtre de visualisation
      // Cela donne la position maximale du scroll
      // -1 pour éviter les erreurs d'arrondi
      const scrollableContentWidth = scrollContainer.scrollWidth;
      const scrollContainerWidth = scrollContainer.getBoundingClientRect().width;
      const maxScrollPosition = scrollableContentWidth - scrollContainerWidth - 1;

      if (scrollContainer.scrollLeft >= maxScrollPosition) return 'end';
      if (scrollContainer.scrollLeft === 0) return 'start';
      return 'middle';
    };

    const scrollHandler = () => setScrollPosition(getScrollPosition());

    scrollContainer.addEventListener('scroll', scrollHandler);
    return () => scrollContainer.removeEventListener('scroll', scrollHandler);
  }, [scrollContainer]);

  return {
    hasScrolled: scrollPosition !== 'start',
    hasFullyScrolled: scrollPosition === 'end',
  };
};

export default useScrollPosition;
