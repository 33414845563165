import { useEffect } from 'react';

import { useStable } from '~/src/common/hooks/use-stable';
import { noop } from '~/src/common/utils/function';

const useFirstScrollAnalytics = (element: HTMLElement | null, handler: () => void = noop) => {
  // Stabilisation du handler pour éviter les appels en boucle
  const stableHandler = useStable(handler);

  // Register du scroll event, pour un seul appel
  useEffect(() => {
    element?.addEventListener('scroll', stableHandler, { once: true });
    return () => element?.removeEventListener('scroll', stableHandler);
  }, [element, stableHandler]);
};

export default useFirstScrollAnalytics;
