import React, { useState } from 'react';

import { useElementVisibility } from '~/src/common/hooks/use-element-visibility';
import { CardInfoWithoutPositionAndCountProperties } from '~/src/common/services/Tracker';
import { sanitize } from '~/src/common/utils/text';
import { useHome } from '~/src/queries/api-ecom/generated/api-ecom';
import HomeCategorySwiper from '~/src/screens/Home/HomeCategory/HomeCategorySwiper';

import { CATALOG_QUERY_PARAMS } from '../queries';
import { HomeCategory as HomeCategoryType } from '../types';

import * as S from './layout';

interface Props {
  lazyRender?: boolean;
  homeCategory: HomeCategoryType;
  categoryAnalyticsProperties: CardInfoWithoutPositionAndCountProperties;
}

const HomeCategory = ({ lazyRender = false, homeCategory, categoryAnalyticsProperties }: Props) => {
  const { name, description, products, id } = homeCategory;

  const { refetch } = useHome(CATALOG_QUERY_PARAMS);

  const productsIds = products.map(p => p.id);

  const isDescriptionDefined = !!description;

  // Au premier render, on affiche les placeholder si lazyRender=true
  // Quand le wrapper arrive dans le viewport, on arrete les placeholders
  const [wrapperRef, isWrapperInViewport] = useElementVisibility();
  const [showPlaceholders, setShowPlaceholders] = useState(lazyRender);
  if (isWrapperInViewport && showPlaceholders) setShowPlaceholders(false);

  return (
    <div id={`${id}-sub`} ref={wrapperRef}>
      <S.TitleContainer>
        <S.Title>{name}</S.Title>
        {isDescriptionDefined ? <S.Description>{sanitize(description)}</S.Description> : null}
      </S.TitleContainer>
      <HomeCategorySwiper
        products={showPlaceholders ? undefined : products}
        placeholders={productsIds}
        subcategoryName={name}
        homeCategory={homeCategory}
        onRefresh={refetch}
        categoryAnalyticsProperties={categoryAnalyticsProperties}
      />
    </div>
  );
};

export default React.memo(HomeCategory);
