import { QueryClient } from '@tanstack/react-query';
import { LDContext } from 'launchdarkly-js-sdk-common';
import type { useRouter } from 'next/router';

import { useApiEcomClientError } from '~/src/common/services/error-handler/error-handler';
import { updateFeatureFlagContext } from '~/src/common/services/FeatureFlag/client';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Toaster from '~/src/common/services/Toaster';
import { ToasterTextUpdateDeliverySlot } from '~/src/common/services/Toaster/Toast/layout';
import Tracker from '~/src/common/services/Tracker';
import { getDeliveryDateWithTimeslotInterval } from '~/src/common/utils/date';
import { noop } from '~/src/common/utils/function';
import { escapeRegExp } from '~/src/common/utils/text';
import {
  getGetAccountProductsQueryKey,
  getGetApiAccountCouponsQueryKey,
  getGetCartQueryKey,
  getGetCategoriesQueryKey,
  getGetList2QueryKey,
  getGetProductIdsQueryKey,
  getGetSearchPopularQueryKey,
  getGetSearchSuggestionsQueryKey,
  getHomeQueryKey,
  getNavigationQueryKey,
  getProductsByCategoryQueryKey,
  getRecompute2QueryKey,
  getRecomputeQueryKey,
  getRootCategoriesQueryKey,
  getSearchItemsQueryKey,
  usePatchCartDelivery2,
} from '~/src/queries/api-ecom/generated/api-ecom';
import { ApiEcomError } from '~/src/queries/services/client';
import { getDeliveryModalStep, isDelivery } from '~/src/screens/App/CatalogLayout/utils';

// La liste des queries à invalider en cas de changement de créneau.
// Si une des clés nécessite un argument, utiliser le placeholder.
const INVALIDATION_PLACEHOLDER = '♻️';
const INVALIDATION_PATTERN_LIST = [
  getGetCartQueryKey(), // Panier
  getRecomputeQueryKey(), // Recompute
  getRecompute2QueryKey(), // Recompute2
  getGetList2QueryKey(INVALIDATION_PLACEHOLDER), // Favoris
  getHomeQueryKey(), // Produits de la home
  getGetCategoriesQueryKey(), // Les catégories
  getRootCategoriesQueryKey(), // Les catégories du menu
  getGetProductIdsQueryKey(INVALIDATION_PLACEHOLDER), // IDs des produits d'une catégorie
  getProductsByCategoryQueryKey(INVALIDATION_PLACEHOLDER), // Produits d'une catégorie
  getGetApiAccountCouponsQueryKey(), // Coupons
  getGetSearchPopularQueryKey(), // Mots clés populaire
  getGetSearchSuggestionsQueryKey({ query: INVALIDATION_PLACEHOLDER }), // Suggestions de recherche
  // @ts-expect-error -- Le placeholder n'est pas compatible avec le typage de la
  // fonction mais est nécessaire pour matcher toutes les routes correspondantes
  getSearchItemsQueryKey({ text: INVALIDATION_PLACEHOLDER }), // Résultats de recherche
  getNavigationQueryKey(), // Navigation
  getGetAccountProductsQueryKey(), // Mes produits
];

// Les patterns ont convertis en une liste de RegExp au format string qui
// sont ensuite combinés pour donner l'expression réguliere d'invalidation
const INVALIDATION_REGEXP_LIST = INVALIDATION_PATTERN_LIST.map(key => {
  const clean = escapeRegExp(String([key].flat().shift()));
  return clean.replace(new RegExp(INVALIDATION_PLACEHOLDER, 'g'), '.*');
});
const INVALIDATION_REGEXP = new RegExp(`^(${INVALIDATION_REGEXP_LIST.join('|')})$`);

export const shouldInvalidateQuery = (queryKey: unknown) => {
  const path = String([queryKey].flat().shift());
  return INVALIDATION_REGEXP.test(path);
};

interface UpdateCartDeliverySlotPayload {
  queryClient: QueryClient;
  router: ReturnType<typeof useRouter>;
  onSuccess: () => void;
}

export const useUpdateCartDeliverySlot = ({
  queryClient,
  onSuccess,
}: UpdateCartDeliverySlotPayload) => {
  const handleError = useApiEcomClientError();
  const { show: openDeliveryModal } = useNiceModal('delivery-modal');

  return usePatchCartDelivery2({
    mutation: {
      onSuccess: cart => {
        queryClient.setQueryData(getGetCartQueryKey(), cart);

        // Invalidation des queries, RQ se chargera de refetch si nécessaire

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => shouldInvalidateQuery(queryKey),
        });

        Tracker.sendEvent('shipping slot toaster viewed');

        const {
          delivery: { deliveryZone, timeSlot, shop },
        } = cart;

        const translationKey = isDelivery(cart.delivery) ? 'delivery' : 'pickup';

        Toaster.notify({
          message: (
            <ToasterTextUpdateDeliverySlot>
              {I18n.translate({
                value: `delivery-modal.${translationKey}-notification`,
                slot: getDeliveryDateWithTimeslotInterval(timeSlot),
              })}
              <p>{I18n.t('delivery-modal.prices-update-notification')}</p>
            </ToasterTextUpdateDeliverySlot>
          ),
          icon: 'valid-big',

          actionButtonLabel: I18n.t('common.modify'),
          onActionButtonClick: () => {
            Tracker.sendEvent('shipping slot toaster click');

            openDeliveryModal({ initialStep: getDeliveryModalStep(cart.delivery) }).catch(noop);
          },
        });

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateFeatureFlagContext((currentContext: LDContext) => ({
          ...currentContext,
          delivery: {
            key: timeSlot.id,
            shop: shop?.id,
            deliveryZone: deliveryZone?.id,
          },
        }));

        onSuccess();
      },
      onError: (error: unknown) => {
        handleError(error as ApiEcomError);
      },
    },
  });
};
