import styled, { css } from 'styled-components';

import Icon from '~/src/common/components/Icon';
import { transparentize } from '~/src/common/utils/style';
import {
  CATALOG_SIDE_PADDING,
  CATALOG_SIDE_PADDING_V2,
} from '~/src/screens/App/CatalogLayout/layout';
import { media } from '~/src/styles-constants';

import {
  CONFIG_SWIPER,
  CONFIG_SWIPER_DEFAULT,
  DESKTOP_ITEM_GAP,
  MOBILE_ITEM_GAP,
  MOBILE_ITEM_PREVIEW_WIDTH,
} from './config';

export const ButtonStyledIcon = styled(Icon)`
  position: relative;
  z-index: 10;
  transition: opacity 0.3s;
  opacity: 0;
`;

export const StyledButton = styled.button<{ direction: 'left' | 'right' }>`
  display: none;

  position: absolute;
  top: 0;

  z-index: 10;

  height: 100%;
  width: ${CATALOG_SIDE_PADDING_V2}px;

  margin: 0;
  padding: 0;

  background-color: ${({ theme }) => theme.palette.common.TRANSPARENT};
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;

  &,
  * {
    outline: none;
  }

  &:hover,
  &:active {
    border-color: inherit;
    box-shadow: none;
  }

  ${media.XL`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  `}

  &[aria-hidden="true"] {
    opacity: 0;
    pointer-events: none;
  }

  & > ${ButtonStyledIcon} {
    ${({ direction }) => css`
      transform: translateX(${direction === 'left' ? '-8' : '8'}px);
    `};
  }
`;

export const MainContainer = styled.div`
  position: relative;

  &:hover {
    ${ButtonStyledIcon} {
      opacity: 1;
    }

    ${StyledButton} {
      background-color: ${({ theme }) => transparentize(theme.palette.common.WHITE, 0.88)};
    }
  }
`;

export const ScrollContainer = styled.div`
  /** Valeurs par défaut */
  --gap: ${MOBILE_ITEM_GAP}px;
  --nb-cards: ${CONFIG_SWIPER_DEFAULT.count};
  --preview-item: ${MOBILE_ITEM_PREVIEW_WIDTH}px;
  --container-padding: ${CATALOG_SIDE_PADDING}px;

  /** Surchage des variables pour certaines tailles d'écran */
  ${media.XL` --gap: ${DESKTOP_ITEM_GAP}px; `}
  ${media.M` --container-padding: ${CATALOG_SIDE_PADDING_V2}px; `}
  ${CONFIG_SWIPER.map(
    ({ count, mq }) => css`
      @media ${mq} {
        --nb-cards: ${count};
      }
    `,
  )};

  position: relative;
  display: flex;
  gap: var(--gap);

  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: calc(-1 * var(--container-padding));
  margin-right: calc(-1 * var(--container-padding));

  overflow: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Contenu additionnel en fin de liste */
  &::before,
  &::after {
    content: '';
    display: block;
    height: 50px;
    flex: 0 0 auto;
    width: calc(var(--container-padding) - var(--gap));
  }

  && > * {
    flex: 0 0 auto;
    width: calc(
      ((100vw - var(--container-padding)) - var(--preview-item) - var(--nb-cards) * var(--gap)) /
        var(--nb-cards)
    );

    ${media.XL`
      width: calc(((100vw - 2 * var(--container-padding)) - var(--nb-cards) * var(--gap)) / var(--nb-cards));
      scroll-snap-align: start;
      scroll-margin: var(--container-padding);
    `}
  }
`;
